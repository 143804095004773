@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&family=Work+Sans:wght@100;200;300;400;500;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', sans-serif;
/* font-family: 'Open-Sans',ssans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffff;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
color: #353534;
  --color-oasis-gray: #6C8695;
  /* font-size: 62.5% !important; */
}
li{
  margin: 0!important;
  padding: 0!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.search{
  &::placeholder{
   font-weight: 600;
  }
  &:focus, &:active{
    outline: none;
    border: none;
  }
}

.rectangle{
  background: rgba(217, 217, 217, 0.30);
backdrop-filter: blur(50px);
background-size: cover;
background-position: 100%;
/* height: 5rem; */
@media screen and (max-width:640px){
  height: 4rem;
}
@media screen and (max-width:290px) {
  height: 5rem;
}
}
a{
  color: black;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.primaryButton{
  @apply text-[#F1F9FF] bg-[#284043] hover:bg-[#F1F9FF] hover:text-[#284043] border-2 hover:border-2 hover:border-[#284043] focus:ring-4 focus:ring-blue-300  rounded-lg text-sm px-8 md:px-12 py-4  md:mb-2 focus:outline-none capitalize md:text-[18px] font-bold md:leading-[25px] text-nowrap;
}
.header{
  @apply capitalize text-[#3B4F5B] md:text-[40px] md:font-semibold font-bold md:leading-[48px] text-xl;
}
.blackHeader{
  @apply capitalize text-[#081116] text-xl md:text-[40px] font-semibold md:leading-[48px];
}
.miniText{
  @apply text-base md:text-2xl font-bold capitalize
}
.secondaryButton{
  @apply bg-[#87A2B3] text-[#29363E] rounded-xl py-3 px-6 md:px-8  text-xs md:text-base capitalize font-bold border-2  border-[#87A2B3] hover:bg-transparent hover:border-2 hover:border-[#29363E]
}
.mainText{
  @apply  text-sm md:text-xl text-[#081116]
}
.section{
  @apply md:py-24 py-16 px-6 lg:px-20 xl:px-40 w-full
}
.welcome{
  @apply text-[#081116] md:leading-10 font-semibold text-sm md:text-[32px]
}
.welcome2{
  @apply text-[#3B4F5B] md:leading-10 md:font-semibold font-bold text-lg md:text-[32px]
}
.smallBlueHeader{
 @apply  text-[#3B4F5B] font-semibold text-xl md:text-[32px] md:leading-[38px] capitalize
}
.aboutSection{
  @apply py-12 md:py-16 px-6 lg:px-20 xl:px-40 w-full
}
.smallBlackText{
  @apply md:text-[32px] md:leading-[38px] md:font-semibold capitalize font-bold text-lg
}
.nav-bar{
box-shadow: 0px 4px 12px 0px rgba(40, 64, 67, 0.04);
}
img{
  /* @apply w-full */
}
.stress{


/* Portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
  bottom: 50rem!important;
}
@media only screen and (min-device-width: 1280px) and (max-device-height: 800px) and (-webkit-min-device-pixel-ratio: 2)   {
  bottom: 15rem!important;
}
@media only screen and (width: 540px)  {
  bottom:1rem!important;
  top: 40rem!important;
}
@media only screen and (min-width:300px) and (max-width: 480px)  {
  bottom:18rem!important;
  top: 36rem!important;
}
@media screen and (max-width:290px) {
  bottom:18rem!important;
  top: 30rem!important;
}
/* Landscape
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1.5) {
bottom: 50rem!important;
} */
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  /* justify-content: space-around; */
  justify-content: space-between;
  gap:2rem
}

.scroll-container img {
  flex-shrink: 0;
  /* width: 100px; Adjust the image width as needed */
  height: auto; /* Maintain aspect ratio */
}
/* ._2p3a{
  width: 800px!important;
} */

.font-OpenSans{
  font-family: 'Open Sans', sans-serif;
}